import runletVue from "@runlet/runlet-vue";
import runletVuetify from "@runlet/runlet-vuetify";
import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.config.productionTip = false

// const url = Vue.config.devtools ? "http://yedek.runlet.me:7788" : ""
const url = "http://yedek.runlet.me:7788"
Vue.use(runletVue, { url: url })
Vue.use(runletVuetify, {})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
